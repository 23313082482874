import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import config from '../config';



const links = [
    { href: '/', label: 'HOME' },
    { href: '/about', label: 'ABOUT' },
    { href: '/service', label: 'SERVICE' },
    { href: '/contact', label: 'CONTACT' }
].map(link => {
    link.key = `nav-link-${link.href}-${link.label}`
    return link
})

const Navbar = () => (
    <nav>
        <span className="logo">
            <img src="//nebula.wsimg.com/765b1ab8828fe305f7abd87fe325bc3c?AccessKeyId=531592D248B589D87A56&amp;disposition=0&amp;alloworigin=1" 
                alt=""
            />
            <span>ICBCclinic.com</span>
        </span>
        <div className="menu">
            <img src="//nebula.wsimg.com/bac1e1a2ddfa15ad597515ab4e09e3ee?AccessKeyId=531592D248B589D87A56&amp;disposition=0&amp;alloworigin=1" 
                alt=""
            />
            <ul>
                {/* {links.map(({ key, href, label }) => (
                    <li key={key}>
                        <Link key={key} to={href} activeClassName="active" exact={true}>
                            {label}
                        </Link>
                    </li>
                ))} */}

                <li key={'nav-link-/-HOME'}>
                        <Link key={'nav-link-/-HOME'} to={'/'} activeClassName="active" exact={true}>
                           HOME
                        </Link>
                </li>

                 <li key={'nav-link-/-ABOUT'}>
                        <Link key={'nav-link-/-ABOUT'} to={'/about'} activeClassName="active" exact={true}>
                           ABOUT
                        </Link>
                  </li>

                  <li key={'nav-link-/service-SERVICE'}>
                        <Link key={'nav-link-/service-SERVICE'} to={'/service'} activeClassName="active" exact={true}>
                            SERVICES
                        </Link>
                  </li>
                  <li key={'nav-link-/location-LOCATION'}>
                        <Link key={'nav-link-/location-LOCATION'} to={'/contact'} activeClassName="active" exact={true}>
                        CONTACT
                        </Link>
                  </li>
                  <li key={'nav-link-/register'}>
                        <Link 
                        key={'nav-link-/register'} to={'#'} activeClassName="active" exact={true}
                        onClick={() => window.location.href = config.southVanHost + "/register" }
                        >
                            REGISTER
                        </Link>
                  </li>
                  <li key={'nav-link-/login'}>
                        <Link 
                        key={'nav-link-/login'} to={'#'} activeClassName="active" exact={true}
                        onClick={() => window.location.href = config.southVanHost + "/login" }
                        >
                            LOGIN
                        </Link>
                  </li>

            </ul>
        </div>
    </nav>
)


export { Navbar }
