import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import { Navbar, Footer } from './components';

const history = createBrowserHistory();


class App extends React.Component {
    

    componentDidMount(){
        window.location.href = 'https://www.southvanmed.com'
    }


    render() {


        // redirect svm
        return null;

        

        return (
            <div id="app">
                <Router history={history}>
                    <div className="container">
                        <Navbar />
                        <main role="main">
                            <Routes />
                        </main>
                        <Footer />
                    </div>
                </Router>
            </div>
        );

    }
}

export default App;
