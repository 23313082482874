import React from 'react';


const contents = [
    {
        title: 'PROFESSIONAL',
        subtitle: 'MEDICAL TEAM',
        description: 'Our physicians are all highly trained and seek to find the best solutions to achieve optimal health.',
        imgUrl: ''
    },
    {
        title: 'LEGAL',
        subtitle: 'DOCUMENTATION',
        description: 'We ensure that your progress is thoroughly documented in our medical charts.  We communicate efficiently with your lawyer should you have one. ',
        imgUrl: ''
    },
    {
        title: 'ALLIED HEALTH',
        subtitle: 'SERVICES',
        description: 'We have onsite physiotherapists and massage therapists ready to assist you.  We can also refer you other allied health providers if necessary.',
        imgUrl: ''
    },
    {
        title: 'CARING TEAM',
        subtitle: 'WE WANT TO HELP',
        description: 'Our staff will do their best to ensure your satisfaction and assist you during the often difficult times following a motor vehicle collision.',
        imgUrl: ''
    },
].map(content => {
    content.key = `content-${content.title}`
    return content
});


const Service = () => (
    <div id="services">
        <div className="row main">
            {contents.map(({ key, title, subtitle, description, imgUrl }) => (
                <div key={key} className="col-md-6">
                    <h2 className="brown font-weight-600 mt-5">
                        {title}
                    </h2>
                    <h3>
                        {subtitle}
                    </h3>
                    <div className="description dark-gray mt-3">
                        {description}
                    </div>
                </div>
            ))}
        </div>
    </div>
)


export { Service };